import React, { lazy, Suspense } from "react";

import AuthLayout from "./layouts/auth-layout";
import PrivateLayout from "./layouts/private-layout";

import PrivateRouteGuard from "./ui-shared/guards/PrivateRouteGuard";
import ResetPassword from "./pages/auth/reset-passwrod";

import Page404 from "./pages/auth/Page404";
import PublicRouteGuard from "./ui-shared/guards/PublicRouteGuard";
import OverlayLoader from "./ui-shared/components/overlay-loader";
import Box from "@mui/material/Box";
import PageTitle from "./ui-shared/components/page-title";
import EarningPage from "./pages/admin/earning";

const SignInPage = lazy(() => import("./pages/auth/sign-in"));
const SignUpPage = lazy(() => import("./pages/auth/sign-up"));

const EncountersPage = lazy(() => import("./pages/patient/encounters/list"));
const EncounterCategoriesPage = lazy(
  () => import("./pages/patient/encounters/create/encounter-categories"),
);
const EncounterSubCategoriesPage = lazy(
  () => import("./pages/patient/encounters/create/encounter-sub-categories"),
);
const EncounterQuestionsStep1Page = lazy(
  () =>
    import(
      "./pages/patient/encounters/create/encounter-questions/encounter-questions-step-1"
    ),
);
const EncounterQuestionsStep2Page = lazy(
  () =>
    import(
      "./pages/patient/encounters/create/encounter-questions/encounter-questions-step-2"
    ),
);
const EncounterQuestionsStep3Page = lazy(
  () =>
    import(
      "./pages/patient/encounters/create/encounter-questions/encounter-questions-step-3"
    ),
);

const EncounterPaymentPage = lazy(
  () => import("./pages/patient/encounters/create/encounter-payment"),
);

const EncounterPaymentSuccessPage = lazy(
  () => import("./pages/patient/encounters/create/encounter-payment-success"),
);

const WaitingRoomPage = lazy(() => import("./pages/non-patient/waiting-room"));

const HealthHistoryPage = lazy(() => import("./pages/patient/health-history"));
const SupportPage = lazy(() => import("./pages/patient/support"));

const DashboardPage = lazy(() => import("./pages/shared/dashboard"));
const ProfilePage = lazy(() => import("./pages/shared/profile"));

const RapidResponsePage = lazy(
  () => import("./pages/non-patient/rapid-response"),
);

//admin tools
const AdminToolsPage = lazy(() => import("./pages/shared/settings"));
const DoctorAdministrationPage = lazy(
  () => import("./pages/admin/doctor-administration"),
);

const SystemWideVariablesPage = lazy(
  () => import("./pages/admin/system-wide-variables"),
);
const PromptsPage = lazy(() => import("./pages/admin/prompts"));

const CreateDoctorPage = lazy(() => import("./pages/admin/doctor/create"));
const EditDoctorPage = lazy(() => import("./pages/admin/doctor/edit"));
const ExpiringCredentialsPage = lazy(
  () => import("./pages/admin/expiring-credentials"),
);

const AlertsPage = lazy(() => import("./pages/admin/alerts"));
const DoctorsPayrollPage = lazy(
  () => import("./pages/admin/doctors-payroll-page"),
);

const FaxStatusPage = lazy(
  () => import("./pages/admin/fax-status-page"),
);
const PatientSearchPage = lazy(() => import("./pages/admin/patient-search"));

const ChangePasswordPage = lazy(() => import("./pages/shared/change-password"));
const LogoutPage = lazy(() => import("./pages/shared/logout"));

export const buildRoutes = ({ isDoctor, isPatient, isSuperAdmin }) => {
  return [
    {
      path: "/",
      element: (
        <PrivateRouteGuard>
          <PrivateLayout />
        </PrivateRouteGuard>
      ),
      children: [
        {
          path: "",
          element: (
            <Suspense fallback={<OverlayLoader />}>
              <DashboardPage />
            </Suspense>
          ),
        },
      ],
    },
    {
      path: "auth",
      element: (
        <PublicRouteGuard>
          <AuthLayout />
        </PublicRouteGuard>
      ),
      children: [
        {
          path: "sign-in",
          element: (
            <Suspense fallback={<OverlayLoader />}>
              <SignInPage />
            </Suspense>
          ),
        },
        {
          path: "sign-up",
          element: (
            <Suspense fallback={<OverlayLoader />}>
              <SignUpPage />
            </Suspense>
          ),
        },
        {
          path: "reset-password",
          element: (
            <Suspense fallback={<OverlayLoader />}>
              <ResetPassword />
            </Suspense>
          ),
        },
      ],
    },
    {
      path: "/profile",
      element: (
        <PrivateRouteGuard>
          <PrivateLayout />
        </PrivateRouteGuard>
      ),
      children: [
        {
          path: "",
          element: (
            <Suspense fallback={<OverlayLoader />}>
              <ProfilePage />
            </Suspense>
          ),
        },
      ],
    },
    {
      path: "/dashboard",
      element: (
        <PrivateRouteGuard>
          <PrivateLayout />
        </PrivateRouteGuard>
      ),
      children: [
        {
          path: "",
          element: (
            <Suspense fallback={<OverlayLoader />}>
              <DashboardPage />
            </Suspense>
          ),
        },
      ],
    },
    {
      path: "/encounters",
      element: (
        <PrivateRouteGuard>
          <PrivateLayout />
        </PrivateRouteGuard>
      ),
      children: [
        {
          path: "",
          element: (
            <Suspense fallback={<OverlayLoader />}>
              <EncountersPage />
            </Suspense>
          ),
        },
        {
          path: "new",
          element: (
            <Suspense fallback={<OverlayLoader />}>
              <EncounterCategoriesPage />
            </Suspense>
          ),
        },
        {
          path: "new/:category",
          element: (
            <Suspense fallback={<OverlayLoader />}>
              <EncounterSubCategoriesPage />
            </Suspense>
          ),
        },
      ],
    },
    {
      path: "/waiting-room",
      element: (
        <PrivateRouteGuard>
          <PrivateLayout />
        </PrivateRouteGuard>
      ),
      children: [
        {
          path: "",
          element: (
            <Suspense fallback={<OverlayLoader />}>
              <WaitingRoomPage />
            </Suspense>
          ),
        },
      ],
    },
    {
      path: "/expiring-credentials",
      element: (
        <PrivateRouteGuard>
          <PrivateLayout />
        </PrivateRouteGuard>
      ),
      children: [
        {
          path: "",
          element: (
            <Suspense fallback={<OverlayLoader />}>
              <ExpiringCredentialsPage />
            </Suspense>
          ),
        },
      ],
    },
    {
      path: "/alerts",
      element: (
        <PrivateRouteGuard>
          <PrivateLayout />
        </PrivateRouteGuard>
      ),
      children: [
        {
          path: "",
          element: (
            <Suspense fallback={<OverlayLoader />}>
              <AlertsPage />
            </Suspense>
          ),
        },
      ],
    },
    {
      path: "/doctors-payroll",
      element: (
        <PrivateRouteGuard>
          <PrivateLayout />
        </PrivateRouteGuard>
      ),
      children: [
        {
          path: "",
          element: (
            <Suspense fallback={<OverlayLoader />}>
              <DoctorsPayrollPage />
            </Suspense>
          ),
        },
      ],
    },
    {
      path: "/fax-status",
      element: (
        <PrivateRouteGuard>
          <PrivateLayout />
        </PrivateRouteGuard>
      ),
      children: [
        {
          path: "",
          element: (
            <Suspense fallback={<OverlayLoader />}>
              <FaxStatusPage />
            </Suspense>
          ),
        },
      ],
    },
    {
      path: "/patient-search",
      element: (
        <PrivateRouteGuard>
          <PrivateLayout />
        </PrivateRouteGuard>
      ),
      children: [
        {
          path: "",
          element: (
            <Suspense fallback={<OverlayLoader />}>
              <PatientSearchPage />
            </Suspense>
          ),
        },
      ],
    },
    {
      path: "/new-encounter",
      element: (
        <PrivateRouteGuard>
          <PrivateLayout />
        </PrivateRouteGuard>
      ),
      children: [
        {
          path: "",
          element: (
            <Suspense fallback={<OverlayLoader />}>
              <EncounterCategoriesPage />
            </Suspense>
          ),
        },
        {
          path: ":category",
          element: (
            <Suspense fallback={<OverlayLoader />}>
              <EncounterSubCategoriesPage />
            </Suspense>
          ),
        },
        {
          path: ":category/:subcategory/step-1",
          element: (
            <Suspense fallback={<OverlayLoader />}>
              <EncounterQuestionsStep1Page />
            </Suspense>
          ),
        },
        {
          path: ":category/:subcategory/step-2",
          element: (
            <Suspense fallback={<OverlayLoader />}>
              <EncounterQuestionsStep2Page />
            </Suspense>
          ),
        },
        {
          path: ":category/:subcategory/step-3",
          element: (
            <Suspense fallback={<OverlayLoader />}>
              <EncounterQuestionsStep3Page />
            </Suspense>
          ),
        },
        {
          path: ":category/:subcategory/payment",
          element: (
            <Suspense fallback={<OverlayLoader />}>
              <EncounterPaymentPage />
            </Suspense>
          ),
        },
        {
          path: ":category/:subcategory/payment/success",
          element: (
            <Suspense fallback={<OverlayLoader />}>
              <EncounterPaymentSuccessPage />
            </Suspense>
          ),
        },
      ],
    },
    {
      path: "health-history",
      element: (
        <PrivateRouteGuard>
          <PrivateLayout />
        </PrivateRouteGuard>
      ),
      children: [
        {
          path: "",
          element: (
            <Suspense fallback={<OverlayLoader />}>
              <HealthHistoryPage />
            </Suspense>
          ),
        },
      ],
    },
    {
      path: "settings",
      element: (
        <PrivateRouteGuard>
          <PrivateLayout />
        </PrivateRouteGuard>
      ),
      children: [
        {
          path: "",
          element: (
            <Suspense fallback={<OverlayLoader />}>
              <AdminToolsPage />
            </Suspense>
          ),
        },
      ],
    },
    {
      path: "doctor-administration",
      element: (
        <PrivateRouteGuard>
          <PrivateLayout />
        </PrivateRouteGuard>
      ),
      children: [
        {
          path: "",
          element: (
            <Suspense fallback={<OverlayLoader />}>
              <DoctorAdministrationPage />
            </Suspense>
          ),
        },
        {
          path: "create",
          element: (
            <Suspense fallback={<OverlayLoader />}>
              <CreateDoctorPage />
            </Suspense>
          ),
        },
        {
          path: "edit/:id",
          element: (
            <Suspense fallback={<OverlayLoader />}>
              <EditDoctorPage />
            </Suspense>
          ),
        },
      ],
    },
    {
      path: "system-wide-variables",
      element: (
        <PrivateRouteGuard>
          <PrivateLayout />
        </PrivateRouteGuard>
      ),
      children: [
        {
          path: "",
          element: (
            <Suspense fallback={<OverlayLoader />}>
              <SystemWideVariablesPage />
            </Suspense>
          ),
        },
      ],
    },
    {
      path: "/change-password",
      element: (
        <PrivateRouteGuard>
          <PrivateLayout />
        </PrivateRouteGuard>
      ),
      children: [
        {
          path: "",
          element: (
            <Suspense fallback={<OverlayLoader />}>
              <ChangePasswordPage />
            </Suspense>
          ),
        },
      ],
    },
    {
      path: "logout",
      element: (
        <PrivateRouteGuard>
          <PrivateLayout />
        </PrivateRouteGuard>
      ),
      children: [
        {
          path: "",
          element: (
            <Suspense fallback={<OverlayLoader />}>
              <LogoutPage />
            </Suspense>
          ),
        },
      ],
    },
    {
      path: "/prescribe",
      element: (
        <PrivateRouteGuard>
          <PrivateLayout />
        </PrivateRouteGuard>
      ),
      children: [
        {
          path: "",
          element: (
            <Suspense fallback={<OverlayLoader />}>
              <Box p={4}>
                <PageTitle>Prescribe</PageTitle>
              </Box>
            </Suspense>
          ),
        },
      ],
    },
    {
      path: "/rapid-responses",
      element: (
        <PrivateRouteGuard>
          <PrivateLayout />
        </PrivateRouteGuard>
      ),
      children: [
        {
          path: "",
          element: (
            <Suspense fallback={<OverlayLoader />}>
              <RapidResponsePage />
            </Suspense>
          ),
        },
      ],
    },
    {
      path: "/prompts",
      element: (
        <PrivateRouteGuard>
          <PrivateLayout />
        </PrivateRouteGuard>
      ),
      children: [
        {
          path: "",
          element: (
            <Suspense fallback={<OverlayLoader />}>
              <PromptsPage />
            </Suspense>
          ),
        },
      ],
    },
    {
      path: "/earnings",
      element: (
        <PrivateRouteGuard>
          <PrivateLayout />
        </PrivateRouteGuard>
      ),
      children: [
        {
          path: "",
          element: (
            <Suspense fallback={<OverlayLoader />}>
              <EarningPage />
            </Suspense>
          ),
        },
      ],
    },
    {
      path: "/membership",
      element: (
        <PrivateRouteGuard>
          <PrivateLayout />
        </PrivateRouteGuard>
      ),
      children: [
        {
          path: "",
          element: (
            <Suspense fallback={<OverlayLoader />}>
              <Box p={4}>
                <PageTitle>Membership</PageTitle>
              </Box>
            </Suspense>
          ),
        },
      ],
    },
    {
      path: "/weight",
      element: (
        <PrivateRouteGuard>
          <PrivateLayout />
        </PrivateRouteGuard>
      ),
      children: [
        {
          path: "",
          element: (
            <Suspense fallback={<OverlayLoader />}>
              <Box p={4}>
                <PageTitle>weight in</PageTitle>
              </Box>
            </Suspense>
          ),
        },
      ],
    },
    {
      path: "/support",
      element: (
        <PrivateRouteGuard>
          <PrivateLayout />
        </PrivateRouteGuard>
      ),
      children: [
        {
          path: "",
          element: (
            <Suspense fallback={<OverlayLoader />}>
              <SupportPage />
            </Suspense>
          ),
        },
      ],
    },
    {
      path: "*",
      element: <AuthLayout />,
      children: [
        {
          path: "*",
          element: <Page404 />,
        },
      ],
    },
  ];
};
