import { ReactComponent as DashboardIcon } from "./icons/grid.svg";
import { ReactComponent as MailIcon } from "./icons/mail.svg";
import { ReactComponent as MembershipIcon } from "./icons/user.svg";
import { ReactComponent as PlusIcon } from "./icons/plus-circle.svg";
import { ReactComponent as HealthHistoryIcon } from "./icons/health-history.svg";
import { ReactComponent as ClockIcon } from "./icons/clock.svg";
import { ReactComponent as PrescribeIcon } from "./icons/check-circle.svg";
import { ReactComponent as EarningsIcon } from "./icons/database.svg";
import { ROLE_KEYS } from "../../di/database";

const navItems = [
  {
    href: "/dashboard",
    icon: DashboardIcon,
    title: "Dashboard",
    roles: [
      ROLE_KEYS.ADMIN,
      ROLE_KEYS.SUPPORT,
      ROLE_KEYS.DOCTOR,
      ROLE_KEYS.PATIENT,
    ],
  },
  {
    href: "/waiting-room",
    icon: ClockIcon,
    title: "Waiting Room",
    roles: [ROLE_KEYS.ADMIN, ROLE_KEYS.SUPPORT, ROLE_KEYS.DOCTOR],
  },
  {
    href: "/new-encounter",
    icon: PlusIcon,
    title: "New Encounter",
    roles: [ROLE_KEYS.PATIENT],
  },
  {
    href: "/encounters",
    icon: MailIcon,
    title: "Encounters",
    roles: [
      ROLE_KEYS.ADMIN,
      ROLE_KEYS.SUPPORT,
      ROLE_KEYS.DOCTOR,
      ROLE_KEYS.PATIENT,
    ],
  },
  {
    href: "/membership",
    icon: MembershipIcon,
    title: "Membership",
    roles: [ROLE_KEYS.PATIENT],
  },
  // {
  //   href: "/weight",
  //   icon: WeightIcon,
  //   title: "Weigh-In",
  //   roles: [ROLE_KEYS.PATIENT],
  // },
  {
    href: "/health-history",
    icon: HealthHistoryIcon,
    title: "Health History",
    roles: [ROLE_KEYS.PATIENT],
  },
  {
    href: "/prescribe",
    icon: PrescribeIcon,
    title: "Prescribe",
    roles: [ROLE_KEYS.ADMIN, ROLE_KEYS.SUPPORT, ROLE_KEYS.DOCTOR],
  },
  {
    href: "/rapid-responses",
    icon: HealthHistoryIcon,
    title: "Rapid Response",
    roles: [ROLE_KEYS.ADMIN, ROLE_KEYS.SUPPORT, ROLE_KEYS.DOCTOR],
  },
  {
    href: "/earnings",
    icon: EarningsIcon,
    title: "Earnings",
    roles: [ROLE_KEYS.ADMIN, ROLE_KEYS.SUPPORT, ROLE_KEYS.DOCTOR],
  },
  // {
  //   href: "/appointments",
  //   icon: Clipboard,
  //   title: "Appointments",
  //   roles: [],
  // },
  // {
  //   href: "/pharmacies",
  //   icon: Clipboard,
  //   title: "Pharmacies",
  //   roles: [],
  // },
  // {
  //   href: "/profile",
  //   icon: Clipboard,
  //   title: "Profile",
  //   roles: [],
  // },
  // {
  //   href: "/work-excuse",
  //   icon: Clipboard,
  //   title: "Work Excuse",
  //   roles: [],
  // },
  // {
  //   href: "/fax-reports",
  //   icon: Clipboard,
  //   title: "Fax Reports",
  //   roles: [],
  // },
  // {
  //   href: "/clinic-analytics",
  //   icon: Clipboard,
  //   title: "Clinic Analytics",
  //   roles: [APP_ROLES.ADMIN],
  // },
  // {
  //   href: "/users",
  //   icon: Clipboard,
  //   title: "Users",
  //   roles: [APP_ROLES.ADMIN],
  // },
];

export default navItems;
