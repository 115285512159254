import axios from "axios";
import { CONFIG } from "../config";
import { getAccessTokenAsync } from "../services/utils";

const privateInstance = axios.create({
  baseURL: CONFIG.API_URL,
  timeout: 30000,
});
const publicInstance = axios.create({
  baseURL: CONFIG.API_URL,
  timeout: 30000,
});

privateInstance.interceptors.request.use(async function (config) {
  config.headers.Authorization = await getAccessTokenAsync();
  return config;
});

const apis = {
  auth: {
    signUp: (data) =>
      publicInstance
        .post("/auth/patient/sign-up", data)
        .then((response) => response.data.data ?? {}),
  },
  stripe: {
    config: (data) =>
      publicInstance
        .get("/config", data)
        .then((response) => response.data ?? {}),

    createIntent: (data) =>
      privateInstance
        .get("/payment/create-payment-intent", data)
        .then((response) => response.data ?? {}),
    encounterStripeProductInfo: (data) =>
      privateInstance.get("/payment/encounter-stripe-product-info", data),

    retrievePaymentIntent: (params) =>
      privateInstance
        .get("/payment/retrieve-payment-intent", { params })
        .then((response) => response.data ?? {}),
  },
  encounterCategories: {
    get: () => privateInstance.get("/encounter-categories/all"),
  },
  encounter: {
    get: (id) => privateInstance.get(`/encounter/${id}`),
    create: (data) => privateInstance.post("/encounter/create", data),
    close: (data) => privateInstance.post("/encounter/close", data),
    refund: (data) => privateInstance.post("/encounter/refund", data),
    reOpen: (data) => privateInstance.post("/encounter/re-open", data),
    getChat: (id) => privateInstance.get(`/encounter/${id}/chat`),
    sendWeightLossFax: (body) =>
      privateInstance.post(`/encounter/weight-loss-fax`, body),
    getWeightLossFax: () =>
      privateInstance.get(`/encounter/faxes/weight-loss-fax`),
  },
  encounterNote: {
    get: (data) => privateInstance.get(`/encounter-note/${data.id}`),
    create: (data) => privateInstance.post("/encounter-note", data),
    edit: (data) => privateInstance.put("/encounter-note", data),
  },
  waitingRoom: {
    list: (params) => privateInstance.get("/waiting-room/list", { params }),
    assignEncounter: (body) =>
      privateInstance.post("/waiting-room/assign-encounter", body),
  },
  patient: {
    list: (params) => privateInstance.get("/patient/list", { params }),
    updatePatientProfile: (data) =>
      privateInstance.put("/patient/update-patient-profile", data),
  },
  googlePlaces: {
    details: (params) => privateInstance.get("/place/details", { params }),
  },
  chat: {
    getChatToken: () => privateInstance.get("/chat/token"),
  },
  chatgpt: {
    getImagingICD10Codes: (params) =>
      privateInstance.get("/chatgpt/imaging-icd10-codes", { params }),

    getLabICD10Codes: (params) =>
      privateInstance.get("/chatgpt/lab-icd10-codes", { params }),

    getEncounterAiRespond: (body) =>
      privateInstance.post("/chatgpt/encounter-ai-respond", body),

    getEncounterAiSummary: (body) =>
      privateInstance.post("/chatgpt/encounter-ai-summary", body),
  },
  user: {
    me: () => privateInstance.get("/user/me"),
    get: (id) => privateInstance.get(`/user/${id}`),
  },
  doctor: {
    updateProfile: (data) =>
      privateInstance
        .put("/doctor/update-doctor-profile", data)
        .then((response) => response.data.data ?? {}),
    updateSignature: (data) =>
      privateInstance
        .put("/doctor/update-doctor-signature", data)
        .then((response) => response.data.data ?? {}),

    listDoctors: (params) => privateInstance.get("/doctor/list", { params }),
    expiringCredentials: (params) =>
      privateInstance.get("/doctor/expiring-credentials", { params }),
  },

  manageUsers: {
    createDoctor: (body) =>
      privateInstance.post("/manage-user/create-doctor", body),
    editDoctor: (body) =>
      privateInstance.post("/manage-user/edit-doctor", body),
    changeDoctorPassword: (body) =>
      privateInstance.post("/manage-user/edit-doctor-password", body),
    suspendUser: (body) =>
      privateInstance.put("/manage-user/suspend-user", body),
    activateUser: (body) =>
      privateInstance.put("/manage-user/activate-user", body),
  },

  rapidResponse: {
    getRapidResponse: (params) =>
      privateInstance.get("/rapid-response/get", { params }),
    createRapidResponse: (body) =>
      privateInstance.post("/rapid-response/create", body),
    editRapidResponse: (body) =>
      privateInstance.post("/rapid-response/edit", body),
    deleteRapidResponse: (body) =>
      privateInstance.post("/rapid-response/delete", body),
  },
  systemSettings: {
    getSystemSettings: () => privateInstance.get("/system-settings/get"),
    updateSystemSettings: (body) =>
      privateInstance.post("/system-settings/update", body),
  },

  getEarnings: (params) => privateInstance.get("/earning/summary", { params }),
  getEarningDetails: (params) =>
    privateInstance.get("/earning/details", { params }),

  getPayrolls: (params) => privateInstance.get("/payroll/summary", { params }),

  getAdminDashboardSummary: (params) =>
    privateInstance.get("/dashboard/admin/summary", { params }),
  getDoctorDashboardSummary: (params) =>
    privateInstance.get("/dashboard/doctor/summary", { params }),
  getPatientDashboardSummary: (params) =>
    privateInstance.get("/dashboard/patient/summary", { params }),
  getPatientSeenDoctors: (params) =>
    privateInstance.get("/dashboard/patient/seen-doctors", { params }),
  getDashboardEncounterDetails: (params) =>
    privateInstance.get("/dashboard/encounters/details", { params }),

  getPrices: () => privateInstance.get("/price/get"),
  createPrice: (body) => privateInstance.post("/price/create", body),
  activatePrice: (body) => privateInstance.post("/price/activate", body),

  getCommissions: () => privateInstance.get("/commission/get"),
  creatCommission: (body) => privateInstance.post("/commission/create", body),
  activateCommission: (body) =>
    privateInstance.post("/commission/activate", body),
};
export default apis;
